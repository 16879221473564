@import "../../_shared";

.track-urls-container {
  ul{
    li{
      display: grid;
      line-height:2em;
      grid-template-columns: 2em minmax(0, 1fr) 3em;
      align-items: center;
      &:hover{
        background: rgba(0, 0, 0, 0.03);
      }
      .url-before {
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
      }

      .url-labels{
        margin-left:.5em;
        .label{
          padding:.25em .5em;
        }
      }
    }
  }
  .track-links-container{
    .track-links-handle{
      text-align:center;
    }
    &.active{
      .track-links-handle{
        display:none;
      }
    }
    &:not(.active){
      ul{
        display:none;
      }
    }
  }
}




.track-source{
  &:not(.current):not(.loading) .play-button{
    opacity:.5;
  }
}
.track-link{
  .url-before{
    opacity:.5;
  }
}
