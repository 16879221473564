@keyframes opacityPulse {
    0% {opacity: 1;}
    50% {opacity: 0.5; }
    100% {opacity: 1;}
}

@keyframes barberpole {
    from { background-position: 0 0; }
    to   { background-position: 60px 30px; }
}

@mixin striped-bg ($color-a:rgba(black, 0.05), $color-b:rgba(0, 0, 0, 0), $direction: 45deg) {
    background-size: 30px 30px;
    background-image: linear-gradient(
        $direction,
        $color-a    25%,
        $color-b    25%,
        $color-b    50%,
        $color-a    50%,
        $color-a    75%,
        $color-b    75%,
        $color-b
    );
    animation: barberpole 0.5s linear infinite;
}

.flash{
  animation: opacityPulse 0.5s linear infinite;
}

.flash-twice{
  animation: opacityPulse 0.2s linear 2;
}

.freeze{
    pointer-events: none;
    opacity:.5;
}

.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.clickable{
  cursor:pointer;
}

.cover-img{
  &img{
    vertical-align: top;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  background-position: center center;
  background-size: cover;
  background-repeat:no-repeat;
}
