.play-button{
  position:relative;
  cursor:pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  .play-button-icons{
    position:absolute;
    box-sizing: border-box;
    margin:auto;
    img{
      width:100%;
      height:100%;
    }

  }
  .loading-circle{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .MuiCircularProgress-root{
      width: 100%;
      height: 100%;
      position:absolute;
      &.progress-bg{
        color:rgba(0,0,0,.1)
      }
      &.process-loading{
      }
      &.process-pc{
      }
    }
  }


}
