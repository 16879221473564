.tracklist{
  > .menu {
    margin-bottom: 2em;
    border-bottom: 1px solid #DDD;
    padding-bottom: 2em;
  }
  .tracks{
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
}
