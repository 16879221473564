@import "../_shared";

$trackHeight: 3em;

[itemprop="track"]{

  width: 100%;
	position:relative;

  &:not(.current):not(:hover){
    opacity:.5;
    &:hover{
      opacity:.75;
    }
    .track-after{
      display:none;
    }
  }

  &.favorite{
    .track-actions >[role="listbox"]{ //dropdown
        .favorite-action{
          display:none!important;
        }
      }
    .track-actions > .track-toggle-favorite{
      &:not(:hover){
        .unfavorite-action{
          display:none;
        }
      }
      &:hover{
        .favorite-action{
          display:none;
        }
      }
    }
  }
  &:not(.favorite){
    .track-actions >[role="listbox"]{ //dropdown
        .unfavorite-action{
          display:none!important;
        }
      }
      .track-actions > .track-toggle-favorite{
        &:not(:hover){
          .favorite-action{
            display:none;
          }
        }
        &:hover{
          .unfavorite-action{
            display:none;
          }
        }
      }
  }

  &:not(.playable){
    .play-button{
      @extend .freeze;
    }
  }

  .track-row{

    &.track-data{
      display: grid;
      padding:.5em;
    	grid-template-columns: calc(2 * #{$trackHeight}) minmax(0, 1fr) 250px;
    }
    &.track-urls-container{
      padding-left:4em;
      ul{
        padding:0;
      }
      &:not(.active){
        display:none;
      }
    }
  }

  &.updating{
    @extend .freeze;
    .track-data{
      //@include striped-bg();
      @extend .flash;
    }
  }
  &.loading{
    .play-button{
      @extend .freeze;
    }
  }

	.track-before{
    display: flex;
    justify-content: center;
    align-items: center;
		height:100%;
		> *{
			flex-basis: $trackHeight;
			flex-shrink:0;
			flex-grow:0;
			&.track-image{
        background-color:#CCC;
        width:$trackHeight;
        height:$trackHeight;
			}
		}
    .play-button{
      margin-right:.25em;
    }
	}

	.track-main {
		padding: 0 1em;
		font-family: sans-serif;
    text-align:left;
    p{
      margin:0;
    }
    .track-duration{
      color:#CCC;
    }
	}
	.track-after{
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .track-links-handle{
      font-size:.8em;
      position:relative;
      .button{
        font-size:1em;
        padding: .5em;
        padding-left:.75em;
      }
      .label{
        font-size:1em;
        padding: 0.5em;
        .icon{
          margin-right:.25em;
        }
      }
    }

    .track-actions-handle{
      margin:auto;
      height: calc(#{$trackHeight} / 2);
      width:auto;
    }
	}

  [itemprop="name"]{
		margin-right:.5em;
	}

	[itemprop="byArtist"]{
		color:#CCC;
	}
	[itemprop="name"]{
		font-weight:bold;
		font-size:1.2em;
	}
	[itemprop="inAlbum"]{
	}


}
