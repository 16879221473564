@import "./_shared";

@font-face {
    font-family: 'PalmerLakePrint';
    src: url('assets/fonts/PalmerLake/PalmerLakePrint-Regular.eot');
    src: url('assets/fonts/PalmerLake/PalmerLakePrint-Regular.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/PalmerLake/PalmerLakePrint-Regular.woff2') format('woff2'),
        url('assets/fonts/PalmerLake/PalmerLakePrint-Regular.woff') format('woff'),
        url('assets/fonts/PalmerLake/PalmerLakePrint-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.handwritten {
	font-family: "PalmerLakePrint",sans-serif;
	line-height: .5em;
	font-weight: normal;
}

html,body,#root{
	min-height:100%;
}
#root{
  width:100%;
  display: flex;
  flex-direction: column;
  padding:.5em;
}

.ui.menu{
  .item{
    &.disabled{
      pointer-events: none;
    }
  }

  &#left-menu{
    position:fixed;
  	border: none;
  	box-shadow: none;
  	border-radius: 0;
  	.menu-title.handwritten{
  		font-size:2.5em;
  	}

  	.item{
  		&.active{
  			background: none;
  			font-weight: bold;
  		}
  		&:hover{
  			background:none;
  		}
  	}
  }
  &#top-menu{
  	background:white;
    border:0;
    padding:.5em;
    #site-logo{
      @extend .handwritten;

      .item{
        font-size:3em;
        margin: 0;
        padding: 0;
        line-height: 1em;
        margin-left: 0.5em;
    	}
    }

  	#user-menu{
  		display:flex;
  		justify-content:center;
  		align-items:center;
  		#user-menu-thumb{
  			background:#EEE;
  			width:1em;
  			height:1em;
  			display:inline-block;
  			margin-right:.5em;
  		}
  		.dropdown{
  			padding-left:0;
  		}
  	}
  }
}



.page-container{
  margin:0!important;
  padding-top:4em!important;
  flex:1;
}

.playlist-manager{
	> .menu {
		margin-bottom: 2em;
    border-bottom: 1px solid #DDD;
    padding-bottom: 2em;
	}

	.playlist-manager-section{

		margin-bottom: 2em;
		overflow: visible;
		clear:both;

		.playlist-manager-section-header{
			padding:1em;
		}
		.playlist-manager-section-content > ul{
			list-style: none;
			margin:0;
			padding:0;
			overflow:hidden;

			li.playlist-item{
		    overflow: hidden;
				position:relative;
				background-color:#f9f9f9;
				border:1px solid #e7e7e7;
				border-radius: 1em;
        display:flex;

        &.disabled-importer{
          @extend .freeze;
        }

				.playlist-figure{
          flex:1;
          flex-grow:0;
					position:relative;
					background-color:#f9f9f9;
          border-bottom:1px solid #e7e7e7;
          display:block;
          width:100%;
          height:100%;
					.playlist-selected{
						position: absolute;
						display:flex;
						justify-content: center;
						align-items: center;
						width: 100%;
						height: 100%;
						background-color:rgba(25, 195, 25, .8);
						>*{
							flex: 1;
					    font-size: 3em;
							line-height:1.5em;
					    color: white;
						}
					}
					>.cover-img{
						width:100%;
						aspect-ratio:1/1;
					}
				}
				.playlist-content{
					position:relative;
					.playlist-title{
						&.handwritten{
							font-size: 3em;
						}
            a{
              color:inherit;
            }
					}
				}

        .post-metas{
          text-align:center;

          &.post-metas-bottom{
            background-color:#f9f9f9;
          }
          &.post-metas-highligh{
            background-color:#FAFBBD;
          }
        }

			}

			&[data-playlist-layout="list"]{

				li.playlist-item{
					margin-bottom:1em;
					justify-content: center;
					align-items: center;
					.playlist-figure{
						flex-basis:10em;

            border-right:1px solid #e7e7e7;
					}
					.playlist-content{
						flex:1;
						align-self:start;
						padding:1em;
            padding-bottom:0;
						text-align: justify;
            display: flex;
            flex-direction: column;
            align-self:stretch;
					}
          .post-metas-highligh{
            display:flex;
            align-self: stretch;
            flex-basis: 3em;
            justify-content:center;
            align-items:center;
          }
				}
			}

			&[data-playlist-layout="grid"]{
				li.playlist-item{
					margin:.5em;
			    float:left;
          flex-direction: column;
					width: 200px;
			    aspect-ratio:9/16;

					.playlist-figure{
						width:100%;
						aspect-ratio:1/1;

					}
					.playlist-content{
				    padding: 1em;
						overflow:hidden;
            flex-grow:1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
						.playlist-title{
              flex-shrink: 0;
							overflow: hidden;
					    text-overflow: ellipsis;
					    display: -webkit-box;
					    -webkit-line-clamp: 3; /* number of lines to show */
					            line-clamp: 3;
					    -webkit-box-orient: vertical;
              margin:0;
						}
            .playlist-excerpt{
              font-size: .8em;
              line-height: 1.5em;
              overflow: hidden;
					    text-overflow: ellipsis;
					    display: -webkit-box;
					    -webkit-line-clamp: 5; /* number of lines to show */
					            line-clamp: 5;
					    -webkit-box-orient: vertical;
            }
					}
				}

			}

		}

	}
}

.App {
  width:80%;
  margin:auto;
  .notice{
    color:white;
    padding:1em;
    margin: 1em 0;
    background-color:sandybrown;
    &.error{
      background-color:indianred;
    }
    &.success{
      background-color:mediumseagreen;
    }
  }
}

/*bottom player*/
.ui.container {
    margin-bottom:3em;
}
.player-controls{
    border-top: 1px solid gainsboro;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    z-index: 100;
    .player-track-progress{
      height:1px;
      background-color: gainsboro;
      .MuiLinearProgress-bar{
        background-color: #8d8d8d!important;
      }
    }
}

.radio{
  padding-bottom:6em;
}

.modal{
	.loading{
		@extend .freeze;
		@extend .flash;
	}
}

#trackQueueModal{
	.playlist-manager{
		.playlist-manager-content{
			height: 50vh;
	    overflow: auto;
		}
	}
}

ul.users-list{
	list-style:none;
	margin:0;
	padding:0;
	overflow:auto;
	li{
		display:inline-block;
		float:left;
		padding-left:.2em;
		a{
			display:block;
			background-color: #CCC;
			width:2em;
			height:2em;
		}
	}
}
#user-menu{
  header{
  	display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  	margin:4em 0;
  	.user-image{
  		width:7em;
  		height:7em;
  		background-color: #CCC;
  	}
  	.user-metas{
  		flex: 1 1;
      padding: 0 1em;
  		h1{
  			display:inline-block;
  		}
  	}
  	.user-actions{

  	}
  }
}
#user-profile{
  header{
  	display:flex;
  	margin:4em 0;
  	.user-image{
  		float:left;
  		width:10em;
  		height:10em;
  		background-color: #CCC;
  		margin-right:1em;
  	}
  	.user-metas{
  		flex: 1 1;
      padding: 0 1em;
  		h1{
  			display:inline-block;
  		}
  	}
  	.user-actions{

  	}
  }
}



#user-settings{
	width:100%;
	&.loading{
		@extend .freeze;
	}
	.ui.container{
		margin-bottom:4em;
	}
	#settings-providers{
		ul#providers-list{
			list-style:none;
			margin:2em 0;
			padding:0;
			li{
				padding:.5em 0;
				.move-provider{
					float:right;
					&:not(:hover){
						opacity:.5
					}
					.chevron{
						&.freeze{
							opacity:.5
						}
					}
				}
				&:hover{
					background-color:rgba(0,0,0,0.025);
				}
			}
		}
	}
}

.post-query{
	> div {
		padding:1em;
	}
}

.post-metas{
  &.post-metas-top,&.post-metas-bottom{
    padding:.5em 0;
  }
  &.post-metas-top{
    border-bottom:1px solid #e7e7e7;
  }
  &.post-metas-bottom{
    margin-top:auto;
    border-top:1px solid #e7e7e7;
  }

  .post-meta-favoriter-list,
  .post-meta-tag-list{
    display:block;
    font-size:.8em;
  }

}

.post-meta{
  position: relative;
  display:inline-block;
  box-sizing:aa;
  margin:0 .1em;
  color:#808080;
  background-color:#e7e7e7;
  border-radius:1em;
  overflow:hidden;
  line-height:2em;
  height:2em;
  a{
    color:#808080;
  }
  .post-meta-icon{
    width:2em;
    height:2em;
    display:inline-block;
  }
  .post-meta-label{
    margin-right:.5em;
  }
  .icon{
    display:inline-block;
    width:100%;
    height:100%;
    filter: grayscale(100%);
  }
  &.post-meta-radio{

    .default-icon{
      &.rss-icon{
        background-image: url('assets/images/rss-icon.png');
      }
      &.radio-icon{
        background-image: url('assets/images/radio-icon.png');
      }
    }

    &:hover .default-icon{
      display:none;
    }
  }
  &.post-meta-status{
     &.post-meta-status-publish{
       display:none;
     }
  }
}

#query-filters{
  .query-filter{
    .label.active a{
      opacity:1;
    }
  }

}

#postlist-filters{
  #postlist-filter-tags{
    ul{
      list-style:none;
      li{
        display:inline-block;
        padding:.1em;
        .label{
          cursor: pointer;
          .icon{
            margin-right: 0;
            margin-left: 0.5em;
            font-size: .92857143em;
            opacity: .5;
          }
        }

      }
    }

  }
}

#advanced-importer{

  &.disabled{
    @extend .freeze;
  }

  .importer-debug{

    border:1px solid #F1F1F1;
    padding:1em;
    margin-top:1em;
    pre{
      position:relative;
      font-size:.8em;
      padding:1em;
      background-color:#F1F1F1;
      max-height: 25em;
      overflow: auto;
      code{
        overflow:visible;
      }
    }
  }
  ul#debug-track-nodes{
    list-style: none;
    padding:0;
    margin:0;
  }

}
