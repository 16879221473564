@import "../_shared";

.ui.container{
  margin-bottom:10em;
}

#wizard-services{
  position:relative;
  ul{
    list-style: none;
    margin:0;
    padding:2em;

    li{
      display: inline-block;
      width:75px;
      transition: opacity .5s;

      opacity:.25;
      background-color:rgba(0,0,0,0.1);
      div{
        aspect-ratio:1;
      }
      &:hover{
        opacity:1;
      }
    }

  }
}

#simple-importer{
  margin:2em 0;
  text-align:center;
  #wizard-submit{
    margin-top:2em;
    display:flex;
    align-items: center;
    justify-content:center;
    .importer-image-container{
      flex-basis:3em;
      flex:1;
      flex-grow:0;
      flex-shrink:0;
      .importer-image {
        background-color: #CCC;
        width: 2.5em;
        height: 2.5em;
        margin-right: .5em;
        &.loading{
          @extend .flash;
        }
      }
    }
  }
}
