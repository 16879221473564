.player{
  background:#e0e1e2;
  position:relative;
  margin-bottom: 1em;
  .embed-player{
    &:not(.active){
      display:none;
    }
    .react-playlister > div {
      width:100%!important;
    }
  }
  .player-controls{
      background-color:#f5f5f5;
      color:white;
      .player-control-seek{
        margin:0!important;
        .bar{
          background:#3f51b5;
        }
      }
      .player-controls-buttons{
        padding: 1em 0;
        display: flex;
        justify-content: center;
        align-items: center;
        > * {
          margin: 0 .35em;
        }
        .player-control-volume{
          display:flex;
          justify-content: center;
          align-items: center;
          .icon.volume{
            cursor:pointer;
            color:rgba(0,0,0,.6);
          }

          >.ui.progress{
            width:10em;
            margin:0;
          }

          &.muted{
            >.ui.progress{
              opacity:.5;
            }
          }
        }
        .player-control-scrobbler,
        .player-control-looping
        {
          &:not(.active){
            opacity:.5;
          }
        }
      }
  }
}
